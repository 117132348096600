<template>
  <div class="collapse menu-collapse" id="collapseMarkets" data-parent="#accordion">
    <div class="menu-accordion p-2">
      <div class="row">
        <div class="col-6" v-for="s in markets" v-bind:key="getKey(s.market_id)" v-bind:class="getMarketClass(s.market_id)">
          <a @click="setMarket(s)">
            <div class="item-wrapper">
              <span class=" item-wrapper-span" v-text="s.market_name"></span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "markets",
  methods: {
    getMarket: function (sport_id) {

      this.$store.dispatch("getMarkets", sport_id);
    },
    getKey: function (match_id) {

      var prefix = "popular-games-" + match_id;
      return Math.random().toString(10).replace('0.', 'odd-id-' + prefix + '-');

    },
    setMarket: function (market) {

      this.market = market;
      this.market_id = market.market_id;
      this.market_name = market.market_name;
      //this.$store.dispatch("setOutcomes", market.outcomes);
      this.$store.dispatch("setMarketID", market.market_id);
    },
    getMarketClass: function (marketID) {

      return parseInt(marketID) === parseInt(this.market_id) ? 'market-list-active' : 'market-list';

    },
    setHour: function (h) {
      this.selected_hour = h;
      //this.EventBus.$emit('filter:hour',h);
      this.$store.dispatch("setHour", h);
    },
    humanHourName: function (h) {
      if (h === -1) {
        return "All Time";
      }

      return h === "" || h === "" || h === 0 ? "Time" : h + " Hours";
    },
    setLeague: function (league) {
      document.getElementById("leagues-btn").click();

      if (league === -1) {
        this.competition_name = "Leagues";
        this.$store.dispatch("setCompetitionID", 0);
        return;
      }

      this.competition_name = league.country_name + " - " + league.competition;
      this.league = league;
      this.competition_name = league.competition;
      this.$store.dispatch("setCompetitionID", league.competition_id);
    },
    setSport: function (sportID) {
      this.sport_name = sportID.sport_name;

      document.getElementById("sports-btn").click();
      this.$store.dispatch("setSportID", sportID);

      if (this.$store.state.current_page === "home") return;

      this.$router.push({ name: "home" });
    },
    getMenuTextClass: function (sportID) {
      return parseInt(this.$store.state.sport_id) === parseInt(sportID)
        ? "ml-2 item-wrapper-span-active"
        : "ml-2 item-wrapper-span";
    },
  },
  computed: {
    markets: function () {
      return this.$store.state.markets
    },
    leagues: function () {
      if (
        this.$store.state.leagues !== undefined &&
        this.$store.state.leagues !== null
      ) {
        // console.log(`LIG-->` + JSON.stringify(this.$store.state.leagues));

        return this.$store.state.leagues;
      }

      return this.$store.state.leagues;
    },
    sports: function () {
      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s, function (k, v) {
        if (v.sport_id > 1) {
          t.push(v);
        }
      });

      return t;
    },
  },
  components: {
  },
  watch: {
    sport_id: function (newValue, oldValue) {

      if (parseInt(newValue) === parseInt(oldValue)) {

        return;

      }

      this.getPopularGames();

    },
  },
  data: function () {
    return {
      market: {},
      competition_name: 'Top League',
      sport_name: 'Sports',
      market_name: 'Market',
      market_id: 1,
      selected_hour: 0,
      hours: [-1, 1, 3, 6, 12, 24, 48],

    }
  },
  mounted: function () {
    this.getMarket(1);
    this.$store.dispatch("getLeague")
  }
}
</script>